<template>
	<div id="Battle">
		<boxmod></boxmod>
		<div class="Battle_nav">
			<div class="item active">
				当前对战
			</div>
			<div class="item" @click="ToBattlehistory">
				对战历史
			</div>
			<div class="item" @click="Show = true">
				创建对战
			</div>
		</div>
		<div class="Battle_con">
			<transition-group name="list" tag="div" class="Battle_list">
				<div class="Battle_item" v-for="(item,index) in ArenaArr" :key="item.id" @click="ToCombat(item.id, item.status)">
					<div class="Battle_item_T">
						{{item.status | Process}}
						<img class="bannpc" v-show="item.ban_npc==1" src="~@/assets/img/bannpc-img.png" alt="">
						<div class="right">
							回合<span>{{item.box_num}}</span>
						</div>
					</div>
					<div class="Battle_item_main">
						<div class="price">
							<div class="coins"></div>
							{{ item.total_bean }}
						</div>
						<div class="person-list mode2" v-if="item.mode==2">
							<div class="person-con">
								<div class="photo" v-for="(item1,index1) in item.game_arena_player.slice(0,2)" :key="index1">
									<img v-if="item1 != null" :src="item1.avatar" alt="">
									<img v-else src="@/assets/img/battle-empty.png">
								</div>
							</div>
							<div class="person-con">
								<div class="photo" v-for="(item1,index1) in item.game_arena_player.slice(2,4)" :key="index1">
									<img v-if="item1 != null" :src="item1.avatar" alt="">
									<img v-else src="@/assets/img/battle-empty.png">
								</div>
							</div>
						</div>
						<div class="person-list mode1" :class="['number'+item.game_arena_player.length]" v-else>
							<div class="photo" v-for="(item1,index1) in item.game_arena_player" :key="index1">
								<img v-if="item1 != null" :src="item1.avatar" alt="">
								<img v-else src="@/assets/img/battle-empty.png">
							</div>
						</div>
					</div>
					
					<div class="join-btn disabled" v-if="item.status==2">查看对战</div>
					<div class="join-btn" v-else>加入对战</div>
					<div class="box-con">
						<div class="box-list">
							<div class="item" v-for="(item1,index1) in item.game_arena_box" :key="index1">
								<img :src="item1.cover" alt="">
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</div>
		<el-pagination style="margin-bottom: 10px;" background layout="prev, pager, next" @current-change="handleCurrentChange"
		 :total="total" :page-size="per_page" :pager-count='5' prev-text="上一页" next-text="下一页" small>
		</el-pagination>

		<van-popup v-model="Show" position="right" :style="{ width: '100%',height: '100%' }">
			<div class="create-title">
				<div class="icon-return" @click="Show=false"></div>
				<p>创建对战</p>
			</div>
			<div class="pattern">
				<div :class="{'active' : situation == '2'}" @click="situation = '2'">双人模式</div>
				<div :class="{'active' : situation == '3'}" @click="situation = '3'">三人模式</div>
				<div :class="{'active' : situation == '4'}" @click="situation = '4'">四人模式</div>
				<div :class="{'active' : situation == '5'}" @click="situation = '5'">2v2模式</div>
			</div>

			<div class="choose">
				选择箱子：<span>{{ choiceList.length }}</span>
				<div class="right">
					<span>总价格</span>
					<div class="coins"></div>
					{{TotalPrice.toFixed(2)}}
				</div>
			</div>

			<div class="blind">
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="blind_list">
							<div class="blind_item" v-for="(item,index) in BlindArr" :key="index" ref="Item">
								<div class="Repair" @click="GetBoxDetail(item)">
									<div class="less" v-show="item.length > 0" @click.stop="SubtractItem(item,index)">-</div>
									<div class="What">
										<!-- <van-icon name="cross" color="#000" size="14" /> -->
										x{{item.length}}
									</div>
									<div class="add" @click.stop="ChoiceItem(item,index)">+</div>
								</div>
								<div class="blind_pic">
									<img :src="item.cover" alt="">
								</div>
								<div class="name">{{ item.name }}</div>
								<div class="price"><div class="coins"></div>{{item.game_bean}}</div>
							</div>
						</div>
					</el-scrollbar>
				</div>
			</div>

			<div class="batch">
				<div class="batch_tit">批量创建</div>
				<div class="batch_options">
					<div class="item" :class="{'selected': radio==item}" v-for="(item,index) in 3" @click="radio = item">{{item}}个</div>
					<div class="item" :class="{'selected': radio==5}" @click="radio = 5">5个</div>
				</div>
			</div>
			<div class="batch" v-if="situation!='5'">
				<div class="batch_tit">禁止机器人</div>
				<div class="batch_options">
					<div class="item" :class="{'selected': ban_npc==1}" @click="ban_npc=1">是</div>
					<div class="item" :class="{'selected': ban_npc==0}" @click="ban_npc=0">否</div>
				</div>
			</div>
			<div class="desc" v-if="config.game_arenas_auto_close==1">
				创建房间后，若在{{config.game_arenas_auto_close_minutes}}分钟内小于2人参与，系统将自动关闭对战房间，房间费用自动退回您的账户。
			</div>
			<div class="establish_btns">
				<div class="btn gray" @click="Cancel">取消创建</div>
				<div class="btn" @click="establish">确认创建</div>
			</div>
			
		</van-popup>
		<!-- 物品遮罩层 -->
		<van-overlay class="box-overlay" :show="boxShow" :lock-scroll="false">
			<div class="wrapper">
				<div class="LogOut_box box_mod_out">
					<div class="close" @click="boxShow = false"><img style="filter: invert(1);" src="@/assets/images/public/close.png" alt=""></div>
					<div class="box_mod_top">
						<img :src="ArenaImg" alt="">
						<p>{{ArenaTitle}}</p>
					</div>
					<!-- <div class="mod_boxlist">
						<div class="mod_boxitem" v-for="(item,index) in ArenaBoxArr" :key="index">
							<div class="mod_boxpic" :class="['lv'+item.lv]">
								<img :src="item.cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
						</div>
					</div> -->
					<!-- <div class="box-detail">
						<div class="icon-return" @click="$router.back()"></div>
						<div class="box-info">
							<div class="img-con">
								<img :src="DetailData.cover" alt="">
							</div>
							<div class="info">
								<div class="name">{{ DetailData.name }}</div>
								<div class="price">
									<div class="coins"></div>
									{{ DetailData.bean }}
								</div>
							</div>
						</div>
						<div class="detail-title">箱内物品</div>
					</div> -->
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<Goods :onlyBox="true" :DetailList="DetailList" :id="DetailData.id"></Goods>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import boxmod from './components/box_mod/index.vue' //箱子展示模块
	import BattleItem from './components/Battle_item/index.vue' //对战房间展示模块
	import Goods from '@/components/PubOpen/Goods.vue';
	import {
		ArenaList,
		ArenaBoxList,
		Arena_Create,
		getConfig,
		BoxDetail
	} from '@/network/api.js'
	import {
		mapState,
		mapActions
	} from 'vuex'
	export default {
		name: 'Battle',
		data() {
			return {
				ArenaArr: [], //对战列表
				total: 0, //数据总数
				per_page: 0, //一页数据
				page: 1, //页码
				Show: false,

				//创建相关
				BlindArr: [], //盲盒列表(处理后)
				situation: '2', //模式选择
				TotalPrice: 0, //选中箱子总价
				choiceList: [], //选中盲盒列表(不显示)
				radio: '1', //批量创建
				config: {
					game_arenas_auto_close: 0,
					game_arenas_auto_close_minutes: 20
				},
				ban_npc: 0,
				boxShow: false,
				ArenaTitle:'',//弹框标题
				ArenaImg:'',//弹框图片
				ArenaBoxArr:[],//箱内物品列表
				DetailList:[],//箱内物品
				DetailData:{},//箱子信息
			}
		},

		created() {
			this.GetArenaList()
			this.handleArenaBoxList()
			this.getConfig()
		},

		methods: {
			...mapActions([
				'Refresh'
			]),
			//宝箱详情
			GetBoxDetail(item){
				this.ArenaTitle = item.name
				this.ArenaImg = item.cover
				this.DetailList = []
				BoxDetail(item.id).then((res) => {
					// console.log(res.data.data)
					this.DetailData = res.data.data
					this.DetailList = res.data.data.box_awards.sort(function(a,b){
						return Number(b.bean) - Number(a.bean)
					})
					this.boxShow = true
				})
			},
			//箱内物品
			GetArenaArr(item){
				this.ArenaTitle = item.name
				this.ArenaImg = item.cover
				this.ArenaBoxArr = item.box_awards
				this.boxShow = true
			},
			getConfig(){
				getConfig().then((res) => {
					const {code, data, message} = res.data
					if(code == 200){
						this.config = data
					}
				})
			},
			//对战列表
			GetArenaList() {
				ArenaList(this.page).then((res) => {
					// console.log(res.data.data)
					res.data.data.data.map(item=>{
						item.game_arena_player = this.SeatScreening(item.user_num,item.game_arena_player)
						if(item.mode!=2){
							item.game_arena_player = item.game_arena_player.reverse();
						}
					})
					this.total = res.data.data.total
					this.per_page = res.data.dataper_page
					this.ArenaArr = res.data.data.data
				})
			},
			SeatScreening(num,arr){
				let A_Arr = []
				for(let i = 0; i < num; i++){
					A_Arr[i] = null
				}
				
				for(let k = 0; k < arr.length; k++){
					A_Arr[arr[k].seat] = arr[k]
				}
				// console.log(A_Arr)
				return A_Arr;
			
			},
			//对战列表分页
			handleCurrentChange(v) {
				this.page = v
				this.GetArenaList()
			},

			//路由跳转历史
			ToBattlehistory() {
				this.$router.push('/Battlehistory')
			},

			//创建相关
			//盲盒列表
			handleArenaBoxList() {
				ArenaBoxList().then((res) => {
					//给每一项新增length属性
					for (let i = 0; i < res.data.data.length; i++) {
						let key = "length";
						let value = 0
						res.data.data[i][key] = value
					}
					res.data.data.forEach(item=>{
						item.box_awards = item.box_awards.sort((a,b)=>{
							return Number(b.bean) - Number(a.bean)
						})
					})
					this.BlindArr = res.data.data.sort((a,b)=>{
						return Number(a.game_bean) - Number(b.game_bean)
					})
				})
			},

			//增
			ChoiceItem(item, index) {
				if (this.choiceList.length < 6) { //若选中数组length < 6
					this.$refs.Item[index].style = 'border: 1px solid rgb(233, 177, 14);'
					this.choiceList.push(item) //添加进选中数组
					item.length += 1 //length + 1
				} else {
					this.$notify({
						type: 'danger',
						message: '最多选择6个盲盒'
					})
				}
			},

			//删
			SubtractItem(item, index) {
				//查找第一个符合条件的下标
				let Item_index = this.choiceList.findIndex((v) => {
					return v.id == item.id
				})
				//删除该下标所对应的对象
				this.choiceList.splice(Item_index, 1)
				item.length -= 1 //length-1
				if (item.length == 0) { //若length == 0，去掉选中效果
					this.$refs.Item[index].style = 'border: 1px solid transparent;'
				}
			},

			//创建
			establish() {
				for (let i = 0; i < this.radio; i++) {
					let box = []
					for (let i = 0; i < this.choiceList.length; i++) {
						box.push(this.choiceList[i].id)
					}
					let params = {
						user_num: this.situation=='5'?'4':this.situation,
						box,
						mode: this.situation=='5'?2:1,
					}
					this.situation!='5' && (params.ban_npc = this.ban_npc)
					Arena_Create(params).then((res) => {
						this.$notify({
							type: 'success',
							message: '创建成功'
						})
						this.Cancel()
						this.Refresh()
					})
				}
			},

			//取消
			Cancel() {
				this.Show = false //关闭侧栏
				this.situation = '2' //对战模式复原
				this.choiceList = [] //选中列表清空
				for (let i = 0; i < this.BlindArr.length; i++) { //箱子length归0
					this.BlindArr[i].length = 0
				}
				for (let k = 0; k < this.$refs.Item.length; k++) { //选中样式清空
					this.$refs.Item[k].style = 'border: 1px solid transparent;'
				}
				this.radio = '1' //批量复原
			},

			//路由跳转详情
			ToCombat(id, status){
				this.$router.push({
					path:'/Combat',
					query:{
						id,
						type:status
					}
				})
			}

		},

		computed: {
			...mapState([
				'NewGroup'
			])
		},

		watch: {
			NewGroup(v) { //监听新房间变化,实时增加新房间
				// console.log(v)
				v.game_arena_player = this.SeatScreening(v.user_num,v.game_arena_player)
				this.ArenaArr.unshift(v)
			},

			//监听选中数组变化，计算总价值
			choiceList(Arr) {
				let Price = 0
				for (let i = 0; i < Arr.length; i++) {
					Price += Number(Arr[i].game_bean)
				}
				this.TotalPrice = Price
			}
		},
		filters:{
			//class过滤
			ProcessName(v){//状态 0=>等待中 1=>进行中 2=>以结束
				switch (v) {
					case 0:
						return 'etc'
						break;
					case 1:
						return 'conduct'
						break;
					case 2:
						return 'end'
						break;
					default:
				}
			},
			
			//进程文本过滤
			Process(v){
				switch (v) {
					case 0:
						return '等待中'
						break;
					case 1:
						return '进行中'
						break;
					case 2:
						return '已结束'
						break;
					default:
				}
			},
			
			//进程字体颜色过滤
			ProcessSty(v){
				switch (v) {
					case 0:
						return 'color: #75cd66'
						break;
					case 1:
						return 'color: #d42d4f;'
						break;
					case 2:
						return 'color: #ffffff;'
						break;
					default:
				}
			},
			
			//进程图标颜色过滤
			ProcessImg(v){
				switch (v) {
					case 0:
						return require('@/assets/images/Vs/etc.png')
						break;
					case 1:
						return require('@/assets/images/Vs/conduct.png')
						break;
					case 2:
						return require('@/assets/images/Vs/end.png')
						break;
					default:
				}
			}
		},
		components: {
			boxmod,
			BattleItem,
			Goods
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Battle {
		width: 352px;
		margin: 0 auto;
		box-sizing: border-box;
		overflow: hidden;
		.Battle_nav {
			width: 100%;
			margin: 16px 0;
			@include HScenter;
			gap: 8px;
			.item {
				width: 112px;
				height: 34px;
				background: linear-gradient(180deg, #23232B 0%, #223 100%);
				border: none;
				@include HScenter;
				cursor: pointer;
				&.active{
					color: #ffd322;
				}
			}
		}
		.Battle_con{
			
		}
		.Battle_list {
			display: flex;
			flex-wrap: wrap;
			gap: 6px;
			width: 354px;
		}
		.Battle_item{
			width: 174px;
			height: 295px;
			background: linear-gradient(180deg, #23232B 0%, #223 100%);
			@include Scenter;
			flex-direction: column;
			.Battle_item_T{
				height: 30px;
				@include Scenter;
				padding: 0 10px;
				font-size: 14px;
				width: 100%;
				position: relative;
				.bannpc{
					width: 24px;
					height: 24px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.right{
					font-size: 12px;
					margin-left: auto;
					@include Scenter;
					span{
						font-size: 14px;
						color: #FFB800;
						margin-left: 3px;
					}
				}
			}
			.Battle_item_main{
				width: 100%;
				height: 130px;
				@include Scenter;
				flex-direction: column;
				background-image: url(~@/assets/img/battle-item-bg.png);
				background-size: 100% 100%;
				margin: 10px 0;
				padding-top: 8px;
			}
			.price{
				@include HScenter;
				font-size: 18px;
				line-height: 22px;
				font-weight: bold;
				.coins{
					width: 14px;
					height: 14px;
					margin-right: 4px;
				}
			}
			.person-list{
				position: relative;
				height: 95px;
				&::before{
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					width: 33px;
					height: 33px;
					transform: translate(-50%, -50%);
					background-image: url(~@/assets/img/icon-vs.png);
					background-size: 100% 100%;
				}
				&.mode2{
					display: flex;
					justify-content: space-between;
					width: 130px;
					margin: 0 auto;
					.person-con{
						width: 45px;
						height: 88px;
						display: flex;
						align-items: center;
						flex-direction: column;
						justify-content: space-between;
						padding: 6px 0;
						background-size: 100% 100%;
						&:nth-child(1){
							background-image: url(~@/assets/img/2v2bg-left.png);
						}
						&:nth-child(2){
							background-image: url(~@/assets/img/2v2bg-right.png);
						}
						.photo{
							img{

							}
						}
					}
				}
				&.mode1{
					@include HScenter;
					flex-wrap: wrap-reverse;
					row-gap: 10px;
					column-gap: 42px;
					&.number3{
						&::before{
							transform: translate(-50%, -20%);
						}
					}
				}
				.photo{
					width: 34px;
					height: 34px;
					overflow: hidden;
					border-radius: 50%;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
			.join-btn{
				width: 148px;
				height: 40px;
				background: url(~@/assets/img/open-btn-bg.png);
				background-size: 100% 100%;
				@include HScenter;
				cursor: pointer;
				&.disabled{
					filter: grayscale(1);
				}
			}
			.box-con{
				height: 62px;
				background: linear-gradient(180deg, #2B2B39 0%, #232331 100%);
				width: 100%;
				@include Scenter;
				margin-top: 17px;
				padding: 0 10px;
			}
			.box-list{
				display: flex;
				align-items: center;
				gap: 6px;
				width: 100%;
				overflow-x: auto;
				overflow-y: hidden;
				height: 41px;
				.item{
					width: 40px;
					height: 40px;
					border: 1px solid #5C5C5C;
					flex-shrink: 0;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.van-popup {
			background: rgba($color: #000000, $alpha: .8);
			box-sizing: border-box;
			.create-title{
				height: 40px;
				background: url(~@/assets/img/rule-header.png);
				background-size: 100% 100%;
				@include HScenter;
				position: relative;
				width: 375px;
				margin: 0 auto;
				.icon-return{
					position: absolute;
					top: 50%;
					left: 14px;
					width: 24px;
					height: 24px;
					background-size: 100% 100%;
					background-image: url(~@/assets/img/icon-return.png);
					cursor: pointer;
					transform: translateY(-50%);
				}
			}
			.pattern {
				width: 348px;
				margin: 18px auto 0;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 8px;
				div {
					width: 170px;
					height: 34px;
					background: linear-gradient(180deg, #23232B 0%, #223 100%);
					line-height: 34px;
					text-align: center;
					font-size: 14px;
					cursor: pointer;

					&.active {
						color: #FFB800;
					}
				}
			}

			.choose {
				width: 348px;
				height: 54px;
				display: flex;
				align-items: center;
				color: #fff;
				font-size: 14px;
				margin: 0 auto;
				span{
					color: #FFB800;
				}
				.right{
					@include Scenter;
					margin-left: auto;
					span{
						color: #B2B2B2;
						font-size: 12px;
					}
					.coins{
						margin: 0 4px;
						width: 14px;
						height: 14px;
					}
				}
			}

			.blind {
				width: 338px;
				margin: 0 auto 10px;
				.scroll-con{
					height: 316px;
					.el-scrollbar__wrap{
						overflow-x: hidden;
					}
				}
				.blind_list {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					row-gap: 6px;
					column-gap: 4px;
					align-content: baseline;
					.blind_item {
						width: 110px;
						height: 120px;
						background: #232331;
						border: 1px solid transparent;
						box-sizing: border-box;
						@include HScenter;
						flex-direction: column;
						position: relative;
						.Repair {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							display: flex;

							.less {
								width: 20px;
								height: 20px;
								line-height: 16px;
								text-align: center;
								background: #FFB800;
								color: #000;
								font-size: 20px;
								cursor: pointer;
							}

							.What {
								margin-left: auto;
								padding: 0 5px 0 2px;
								position: relative;
								background: #FFB800;
								height: 20px;
								line-height: 20px;
								color: #000;
								&::before{
									content: '';
									position: absolute;
									left: -15px;
									border-style: solid;
									border-width: 10px 8px;
									border-color: #FFB800 #FFB800 transparent transparent;
								}
							}
							.add {
								width: 20px;
								height: 20px;
								line-height: 16px;
								text-align: center;
								background: #FFB800;
								color: #000;
								font-size: 20px;
								position: absolute;
								right: 0;
								bottom: 0;
								cursor: pointer;
							}
						}

						.blind_pic {
							width: 70px;
							height: 70px;
							margin: 0 auto;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								vertical-align: middle;
								margin: 100%;
								max-height: 100%;
							}
						}

						.name{
							@include wordhide;
							color: #bbb;
							margin-top: 4px;
							max-width: 90%;
						}
						.price{
							@include Scenter;
							margin-top: 2px;
							.coins{
								width: 14px;
								height: 14px;
							}
						}
					}
				}
			}

			.batch {
				width: 338px;
				background: #232331;
				margin: 0 auto;
				padding: 14px 20px;
				.batch_tit {
					font-size: 16px;
					color: #fff;
					margin-right: 10px;
				}
				.batch_options{
					margin-top: 18px;
					display: flex;
					.item{
						width: 25%;
						position: relative;
						padding-left: 26px;
						cursor: pointer;
						&::before{
							content: '';
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							width: 20px;
							height: 20px;
							background-image: url(~@/assets/img/icon-check.png);
							background-size: 100% 100%;
						}
						&.selected{
							&::before{
								background-image: url(~@/assets/img/icon-checked.png);
							}
							
						}
					}
				}
			}
			.desc{
				width: 318px;
				margin: 10px auto 30px;
				color: #999;
			}
			.establish_btns {
				@include Hcenter;
				gap: 10px;
				margin-bottom: 15px;
				.btn{
					width: 150px;
					height: 42px;
					@include HScenter;
					font-size: 14px;
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					cursor: pointer;
					&.gray{
						filter: grayscale(1);
					}
				}
			}
		}
	}
	.box_mod_out{
		padding:20px 0 !important;
		width: 350px !important;
		.scroll-con{
			width: 330px;
			height: 400px;
			margin: 0 auto;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.box_mod_top{
			width: 100%;
			height: 34px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #11151f;
			padding-left: 10px;
			padding-bottom: 10px;
			margin-bottom: 10px;
			img{
				height: 34px;
				vertical-align: middle;
				margin-right: 10px;
			}
			p{
				font-size: 14px;
				color: #fff;
				font-weight: bold;
			}
		}
		.mod_boxlist{
			width: 100%;
			height: 400px;
			background: #20222b;
			border-radius:0 0 6px 6px;
			overflow-y: auto;
			padding:5px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.mod_boxitem{
				display: inline-block;
				width: 48%;
				margin-bottom: 10px;
				background: rgba($color: #fff, $alpha: .02);
				border-radius: 4px;
				text-align: center;
				padding-bottom: 10px;
				.mod_boxpic{
					width: 120px;
					height: 120px;
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;
					background-size: auto 70%;
					background-position: center center;
					background-repeat: no-repeat;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
					img {
						vertical-align: middle;
						max-width: 80%;
						max-height: 80%;
					}
				}
				p{
					font-size: 14px;
					padding: 0 8px;
					text-align: center;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #fff;
				}
				span {
					display: block;
					margin-top: 5px;
					text-align: center;
					font-size: 14px;
					color: #75cd66;
					margin: 5px 0;
				
					img {
						width: 16px;
						height: 16px;
						vertical-align: middle;
						margin-bottom: 2px;
					}
				}
			}
			
		}
		
	}
	.box-overlay{
		z-index: 10005 !important;
	}
	.box-detail{
		position: relative;
		@include HScenter;
		background-image: url(~@/assets/img/box-detail-bg.png);
		background-size: 100% 100%;
		height: 182px;
		margin-bottom: 10px;
		.icon-return{
			position: absolute;
			top: 10px;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
		}
		.box-info{
			@include HScenter;
			gap: 12px;
			.img-con{
				width: 90px;
				height: 90px;
			}
			.info{
				width: 100px;
				.name{
					@include wordhideRow(2);
					margin-bottom: 4px;
					color: #bbb;
				}
				.price{
					@include Scenter;
					.coins{
						width: 20px;
						height: 20px;
						background-image: url(~@/assets/img/coins-yellow.svg);
					}
				}
			}
		}
		.detail-title{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30px;
			@include HScenter;
		}
	}
</style>
